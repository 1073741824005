import request from '@/api/request.js'

/**
 * @Description: 打印机
 */

// 分页查询
export function pageWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsPrinter(data) {
    return request({
        url: '/api/wmsPrinter/batchDelete',
        method: 'POST',
        data
    })
}

