
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑打印机"
    :visible.sync="visible"
    width="600px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="24">
                <el-form-item label="名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="编号" prop="code">
              <el-input v-model="row.code" placeholder="请输入编号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="禁用" prop="disable">
                <el-radio-group v-model="row.disable"
                :disabled="false"                
                size="mini">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
               </el-radio-group>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="工作台" prop="workTableId">
                <el-select ref="workTableId"
                :disabled="false"
                                v-model="row.workTableId"
                                prop="workTableId"
                                placeholder="请选择工作台"
                                clearable
                                filterable
                                :filter-method="listWmsWorkTable"
                            >
                            <el-option
                                v-for="item in optionsOfWmsWorkTable"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                  :disabled="item.disable == 1"
                            ></el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsPrinter } from "@/api/wms/wmsPrinter"

import { listWmsWorkTable } from "@/api/wms/wmsWorkTable"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            name: null,
            code: null,
            disable: null,
            workTableId: null,
            workTableName: null,
      },
      rules: {
            name: [
                { required: true, message: '请输入名称!'},
            ],
            code: [
                { required: true, message: '请输入编号!'},
            ],
            disable: [
                { required: true, message: '请输入禁用!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsWorkTable: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsWorkTable()

  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    listWmsWorkTable(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWorkTable(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWorkTable = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsPrinter(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
